const bsBreakpoints = require('bs-breakpoints/dist/bs-breakpoints');

function debounce(func, wait, immediate) {
    let timeout;
    return function() {
        const context = this,
            args = arguments;
        const later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

function checkResponsiveLink($el) {
    // Set slideshow image from html data attribute based on current viewport
    const linkData = $el.data('responsive-bg-img-link');
    let imageLink = '';
    if (
        bsBreakpoints.getCurrentBreakpoint() === 'xSmall' ||
        bsBreakpoints.getCurrentBreakpoint() === 'small' ||
        bsBreakpoints.getCurrentBreakpoint() === 'medium'
    ) {
        imageLink = linkData.imageLinkMobile;
    } else {
        imageLink = linkData.imageLink;
    }
    if ($el.attr('href') !== imageLink) {
        $el.attr('href', imageLink);
    }
}

module.exports = function() {
    bsBreakpoints.init();

    $('[data-responsive-bg-img-link]').each(function() {
        const $el = $(this);
        checkResponsiveLink($el);

        // Watch screen resize and throttle resize call every 200ms
        const checkAgainLink = debounce(function() {
            checkResponsiveLink($el);
        }, 200);
        $(window).on('resize', checkAgainLink);
    });
};
