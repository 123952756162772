//slick
// Carousel for home page products

require('slick-carousel');
window.jQuery = window.$ = require('jquery');
require('lazyloadxt/dist/jquery.lazyloadxt.extra.min');

function slickApply(selector) {
	const $el = $(selector);
	const isAddToCartChild = !!$el.closest('#addToCartMessage-modal').length;

	$el.not('.slick-initialized').slick({
		dots: false,
		speed: 350,
		centerMode: false,
		slidesToShow: 4,
		infinite: true,
		responsive: [
			{
				breakpoint: 1500,
				settings: {
					arrows: true,
					slidesToShow: 4,
					slidesToScroll: 2,
				},
			},
			{
				breakpoint: 1200,
				settings: {
					arrows: true,
					slidesToShow: 3,
					slidesToScroll: 2,
				},
			},
			{
				breakpoint: 992,
				settings: {
					arrows: true,
					centerMode: true,
					// centerPadding: '50px',
					slidesToShow: 2,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 769,
				settings: {
					arrows: true,
					centerMode: true,
					centerPadding: isAddToCartChild ? '0' : '50px',
					slidesToShow: 2,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 544,
				settings: {
					arrows: true,
					centerMode: true,
					centerPadding: isAddToCartChild ? '0' : '50px',
					dots: isAddToCartChild,
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	});
}

function tabCarousel() {
	// carousel with tabs
	slickApply('.tab-pane-carousel');
	$('.tab-pane-carousel').slick('setPosition'); // Slick recalculate widths
	// $('.tab-carousel [data-toggle="tab"]').on('shown.bs.tab', function() {
	// 	const $tabPane = $($(this).attr('href'));
	// 	$tabPane.find('.tab-pane-carousel').slick('refresh');
	// });
	$('#addToCartMessage-modal').on('transitionstart', function() {
		slickApply('.tab-pane-carousel');
		 $(window).lazyLoadXT();
		$('.tab-pane-carousel').slick('setPosition'); // Slick recalculate widths
	});
}

function productCarousel() {
	// carousel for categoryLanding, blog and PDP
	slickApply('.product-carousel');
	$('.product-carousel').slick('setPosition'); // Slick recalculate widths
}

function relatedProductCarousel() {
	// carousel for related products in add-to-cart modal
	slickApply('.related-products-carousel');
	$('#addToCartMessage-modal').on('transitionstart', function() {
		$('.related-products-carousel').slick('setPosition'); // Slick recalculate widths
	});
}

function trustBannerCarousel() {
	// carousel for trust banner on PDP and MTO modal
	const $el = $('.trust-banner-carousel');
	$el.not('.slick-initialized').slick({
		dots: false,
		arrows: false,
		speed: 350,
		centerMode: false,
		slidesToShow: 3,
		slidesToScroll: 1,
		infinite: true,
		autoplay: true,
		autoplaySpeed: 3000,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 1,
				},
			},
		],
	});
	$el.slick('setPosition'); // Slick recalculate widths
	$('.trust-banner-carousel-container').addClass('visible');
}

function galleryCarousel() {
	const $el = $('.img-galery-carousel');
	$el.not('.slick-initialized').slick({
		dots: false,
		arrows: true,
		speed: 350,
		centerMode: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		infinite: true,
		autoplay: true,
		autoplaySpeed: 3000,
		adaptiveHeight: true,
	});
	$el.slick('setPosition'); // Slick recalculate widths
}

function videoCallCarousel() {
	const $el = $('.video-call-carousel');
	$el.not('.slick-initialized').slick({
		dots: false,
		arrows: false,
		speed: 350,
		slidesToShow: 3,
		slidesToScroll: 1,
		infinite: true,
		autoplay: false,
		adaptiveHeight: false,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					dots: true,
					arrows: true,
					slidesToShow: 1,
				},
			},
		],
	});
	$el.slick('setPosition'); // Slick recalculate widths
}

function lazyLoadCarousel() {
	$('.slick-arrow, .carousel-control-prev, .carousel-control-next').on('click', function (){
		$(window).lazyLoadXT();
	});
}

function informationalStrip() {
	// Carousel for informational strip content
	const $el = $('.informational-strip');
	$el.not('.slick-initialized').slick({
		dots: false,
		arrows: false,
		speed: 350,
		centerMode: false,
		slidesToShow: 3,
		slidesToScroll: 1,
		infinite: true,
		autoplay: true,
		autoplaySpeed: 3000,
		responsive: [
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 1.75
				},
			},
		],
	});
	$el.slick('setPosition'); // Slick recalculate widths
}

function reviewCarousel() {
	const $el = $('.review-carousel-contents-wrapper');
	$el.not('.slick-initialized').slick({
		dots: false,
		arrows: true,
		centerMode: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		adaptiveHeight: true,
	});
	$el.slick('setPosition'); // Slick recalculate widths
}

function reviewCarouselWithRating() {
	const $el = $('.review-carousel-contents');
	$el.not('.slick-initialized').slick({
		dots: false,
		arrows: true,
		centerMode: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		adaptiveHeight: true,
	});
	$el.slick('setPosition'); // Slick recalculate widths
}

module.exports = {
	tabCarousel: tabCarousel,
	productCarousel: productCarousel,
	trustBannerCarousel: trustBannerCarousel,
	relatedProductCarousel: relatedProductCarousel,
	galleryCarousel: galleryCarousel,
	videoCallCarousel: videoCallCarousel,
	lazyLoadCarousel: lazyLoadCarousel,
	informationalStrip: informationalStrip,
	reviewCarousel: reviewCarousel,
	reviewCarouselWithRating: reviewCarouselWithRating
};
